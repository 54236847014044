html, body {
  max-width: 100%;
  /*overflow-x: hidden;*/
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  top: 0;
  left: 0;
  z-index: 100; /* Just to keep it at the very top */
  background-color: #070a1b;
  /*background-image: -webkit-linear-gradient(-20deg, #2e304f, #1c1e30);
  background-image: linear-gradient(-20deg, #2e304f, #1c1e30);*/
}

.marquee {
  width: 100%;
  line-height: 20px;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
  margin: 5px;
  display: inline-block;
  padding-left: 100%;
  animation: marquee 25s linear infinite;
}
@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}

.App {
  display: grid;
  color: white;
}

.navbar {
  z-index: 100;
  display: flex;
  flex-direction: row;
  width: auto;
  height: 60px;
  background-color: #141729;
}
.nameContainer {
  text-align: center;
  margin-left: 150px;
  width: 130px;
}

.navbarItem {
  margin: auto 0;
  padding-left: 20px;
}

.searchBox {
  height: 35px;
  width: 200px;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  background-color: #292c3a;
}

.searchContainer input {
  color: lightgray;
  font-size: 15px;
  height: 10px;
  width: 200px;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 2px;
  background-color: #292c3a;
  padding: 10px;
  margin-left: 35px;
}

.leftMenu {
  position: absolute;
  height: 100%;
  width: 200px;
  background-color: #141729;
}

.pageContent {
  overflow: hidden;
  width: auto;
  height: 100%;
  /*margin-left: 215px;*/
}

hr {
  z-index: 10;
  margin: 0;
  color: #373737;
}

.title {
  font-size: 72px;
}
.gradient {
  background: linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(129,21,163,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container {
  margin: 100px;
  padding: 20px;
  background-color: #141729;
}

.login {
  padding: 0 30px;
  display: flex;
  margin-left: auto;
}

a {
  color: lightgray;
  justify-content: center;
  text-decoration: none;
}

a:hover {
  color: ghostwhite;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.largeIconContainer {
  height: 64px;
  width: 64px;
}

.bazaarContainer {
  margin: 30px 150px 10px 150px;
  background-color: rgba(0, 0, 0, 0);
}
.parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 0px;
}

.div1 { grid-area: 1 / 1 / 3 / 3; }
.div2 { grid-area: 1 / 3 / 3 / 4; }

#chart {
  background: #0e3040;
  color: #67809f;
  width: 100%;
  height: 100%;
}

.chartContainer {
  padding: 20px;
  background-color: #141729;
  grid-area: 1 / 1 / 3 / 3;
}
.infoContainer {
  background-color: #141729;
  grid-area: 1 / 3 / 3 / 4;
}

.titleContainer {
  text-align: left;
}

.mediumText {
  font-size: 28px;
}

.bigText {
  font-size: 32px;
}

.change {
  font-size: 24px;
}

.green {
  color: green;
}
.red {
  color: red;
}

.bazaarHeader {
  width: 100%;
  height: 250px;
  padding: 30px 150px;
  left: 0;
  top: 0;
  background-color: #141729;
  position: relative;
}

.buttonContainer {
  text-align: left;
  height: 34px;
  position: absolute;
  bottom: 6px;
}

.button {
  color: lightgray;
  padding: 15px 0 20px 0;
  margin-right: 15px;
  font-size: 16px;
}

.buttonActive {
  border-bottom: 3px solid lightgray;
}

.button:hover:not(.soonTM) {
  color: ghostwhite;
}

.soonTM {
  color: gray;
}

.subTitle {
  margin: 10px;
  text-align: left;
  font-size: 24px;
}

table {
  border-collapse: collapse;
  margin: 30px;
  text-align: left;
  width: 40%;
}

.tableTitle {
  color: #95979e;
}

.tableValue {
  text-align: right;
}

.tableFlex {
  display: flex;
  justify-content: space-evenly;
}

tr, td {
  padding: 10px;
  border-bottom: 1px gray solid;
}

tr:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

tr:last-child {
  border-bottom: none;
}

footer {
  width: 100%;
  height: 250px;
  background-color: #141729;
  margin-top: 50px;
  bottom: 0;
}

.footerContainer {
  padding: 20px 20%;
}
